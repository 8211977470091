import { Controller } from '@hotwired/stimulus';
import StarRating from 'star-rating.js';

import 'star-rating.js/dist/star-rating.css';

export default class extends Controller {
  static targets = ['rating'];

  connect() {
    if (this.hasRatingTarget) {
      new StarRating(this.ratingTarget, {
        clearable: false,
        tooltip: false
      });
    }
  }
}