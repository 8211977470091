import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import Clipboard from 'stimulus-clipboard';
import Dropdown from 'stimulus-dropdown';

const application = Application.start();
const controllers = import.meta.globEager('./**/*_controller.js');

application.register('dropdown', Dropdown);
application.register('clipboard', Clipboard);

registerControllers(application, controllers);

application.debug = false;
window.Stimulus   = application;
