import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['withdraw', 'amount', 'total'];
  static values = { balance: Number };

  connect() {
  }

  update() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GBP',
    });

    this.amountTarget.innerHTML = formatter.format(this.withdrawTarget.value);
    this.totalTarget.innerHTML = formatter.format(this.balanceValue - this.withdrawTarget.value);
  }
}