import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output', 'input'];

  connect() { }

  readURL() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (event) => {
       this.outputTarget.src = reader.result
     }

     reader.readAsDataURL(this.inputTarget.files[0]);
   }
 }
}