import { Controller } from '@hotwired/stimulus';
import IMask from 'imask';

export default class extends Controller {
  static values = {
    type: String
  };

  connect() {
    let options = {};

    switch (this.typeValue) {
      case 'currency':
        options = this.currency();
        break;
    }

    IMask(this.element, options);
  }

  currency() {
    return {
      mask: Number,

      scale: 2,
      signed: false,
      thousandsSeparator: '',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: ['.'],

      min: 0,
      max: 100000
    }
  }
}
