import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  close(event) {
    event.preventDefault();

    this.element.innerHTML = null;
    this.turboFrameModal.src = null;
  }

  escClose(event) {
    if (event.key === 'Escape') this.close();
  }

  get turboFrameModal() {
    return document.querySelector('turbo-frame[id="modal"]');
  }
}