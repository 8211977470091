import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tip', 'amount', 'total'];

  connect() {
  }

  update() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GBP',
    });

    const value = formatter.format(this.tipTarget.value)
    
    this.amountTarget.innerHTML = value;
    this.totalTarget.innerHTML = value;
  }
}